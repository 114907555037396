window.$ = require('jquery');
window.jQuery = $;

/*
  The application uses libraries that are not fully compatible with jQuery 3:
    1. jquery-ujs for unobtrusive scripting.
      The library is not maintained anymore. Possible solutions are migrating to a different library or rewriting
      the code not to need one.
    2. jquery.tinymce adapter for tinymce editor (version 4.0.26).
      One solution is to upgrade tinymce to the newest version and use the bundled adapter. However, the license
      is now GPLv2+ and requires payment for commercial use. Since the application only requires basic functionality,
      perhaps a better solution is to migrate to a different rich text editor library.
    3. highcharts (version 3.0.10).
      The solution is to migrate to a newer highcharts version that does not depend on jQuery (ideally the same
      version that is in use in the new open 360 playground).

  Because of that the jquery-migrate tool is needed for the application to work properly.
  After all of the points above are addressed the jquery-migrate tool can be removed, but please note that it might
  result in some other issues unknown at the time of writing this comment.
 */
jQuery.migrateMute = true;
require('jquery-migrate');
